import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VerifyEmailService {
  baseUrl = environment.apiUrl;
  endpoint = 'email';

  constructor(private http: HttpClient) {}

  // VERIFY EMAIL
  verify(verifyData: any): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/${this.endpoint}/verify/${verifyData.user_id}/${verifyData.hash}?expires=${verifyData.expires}&signature=${verifyData.signature}`,
    );
  }

  //RESEND VERIFY EMAIL
  verifyResend(verifyEmail: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${this.endpoint}/resend/${verifyEmail}`);
  }
}
